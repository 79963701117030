import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useColorModes } from '@coreui/react';
import './scss/style.scss';

const AppLayout = React.lazy(() => import('./layout/AppLayout'));

export const router = createBrowserRouter([
  {
    path: '*',
    element: <AppLayout/>
  }  
]);

if(process.env.NODE_ENV !== 'development'){
	console.log = () => {};
	console.warn = () => {};
}

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  const storedTheme = useSelector((state) => state.session.theme)
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }

    setColorMode(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RouterProvider router={router}/>
  )
}

export default App
