import _forEach from 'lodash/forEach';
import { types } from '../actionTypes/GroupActionTypes';

const GroupReducer = (state = [], action) => {
  const { payload } = action
  switch (action.type) {
    case types.GET_ALL_GROUPS_REQUEST:
      return {
        ...state,
        groupListLoading: true
      }
    case types.GET_ALL_GROUPS_SUCCESS:
      return {
        ...state,
        groupListLoading: false,
        allGroupsList: payload.Groups,
        totalGroups: payload.TotalRecords
      }
    case types.GET_ALL_GROUPS_FAILURE:
      return {
        ...state,
        groupListLoading: false,
      }
    case types.GET_GROUP_DETAILS_REQUEST:
      return {
        ...state,
        groupDetailsLoading: true,
      }
    case types.GET_GROUP_DETAILS_SUCCESS:
      let mergedGroupDetails = {}
      if (action.extraData.StartIndex > 1) {
        mergedGroupDetails = {
          ...state.groupDetails, ApprovedFeeds:
          {
            ...state.groupDetails.ApprovedFeeds, lstFeeds:
              [...state.groupDetails.ApprovedFeeds.lstFeeds, ...payload.ApprovedFeeds.lstFeeds]
          },
          UnApprovedFeeds:
          {
            ...state.groupDetails.UnApprovedFeeds, lstFeeds:
              [...state.groupDetails.UnApprovedFeeds.lstFeeds, ...payload.UnApprovedFeeds.lstFeeds]
          },
        }
      } else {
        mergedGroupDetails = payload
      }
      return {
        ...state,
        groupDetailsLoading: false,
        groupDetails: mergedGroupDetails
      }
    case types.GET_GROUP_DETAILS_FAILURE:
      return {
        ...state,
        groupDetailsLoading: false,
      }
    case types.REMOVE_USER_FROM_GROUP_REQUEST:
      return {
        ...state,
        removeUserLoading: false,
      }
    case types.REMOVE_USER_FROM_GROUP_SUCCESS:
      return {
        ...state,
        removeUserLoading: false,
      }
    case types.REMOVE_USER_FROM_GROUP_FAILURE:
      return {
        ...state,
        removeUserLoading: false,
      }
    case types.DELETE_GROUP_REQUEST:
      return {
        ...state,
        deleteGroupLoading: true
      }
    case types.DELETE_GROUP_SUCCESS:
      return {
        ...state,
        deleteGroupLoading: false,
      }
    case types.DELETE_GROUP_FAILURE:
      return {
        ...state,
        deleteGroupLoading: false
      }
    case types.EDIT_POST_REQUEST:
      return {
        ...state,
        editGroupLoading: true
      }
    case types.EDIT_POST_SUCCESS:
      return {
        ...state,
        editGroupLoading: false,
      }
    case types.EDIT_POST_FAILURE:
      return {
        ...state,
        editGroupLoading: false
      }
    case types.GET_HASH_TAGS_REQUEST: {
      return { ...state };
    }
    case types.GET_HASH_TAGS_SUCCESS: {
      return { ...state, isHashTagLoading: false, hashTagData: payload };
    }
    case types.GET_HASH_TAGS_FAILURE: {
      return { ...state };
    }
    case types.GET_GROUP_FEEDS_REQUEST:
      return {
        ...state,
        groupFeedsLoading: true
      }
    case types.GET_GROUP_FEEDS_SUCCESS:
      let mergedFeeds = {
        ...state.groupDetails, ApprovedFeeds:
        {
          ...state.groupDetails.ApprovedFeeds, lstFeeds:
            [...state.groupDetails.ApprovedFeeds.lstFeeds, ...payload.ApprovedFeeds.lstFeeds]
        },
        UnApprovedFeeds:
        {
          ...state.groupDetails.UnApprovedFeeds, lstFeeds:
            [...state.groupDetails.UnApprovedFeeds.lstFeeds, ...payload.UnApprovedFeeds.lstFeeds]
        },
      }
      return {
        ...state,
        groupFeedsLoading: false,
        groupDetails: mergedFeeds
      }

    case types.GET_GROUP_FEEDS_FAILURE:
      return {
        ...state,
        groupFeedsLoading: false
      }
    case types.MAKE_USER_ADMIN_REQUEST:
      return {
        ...state,
        makeUserAdminLoading: true
      }
    case types.MAKE_USER_ADMIN_SUCCESS:
      let updatedData = []
      _forEach(state.groupDetails.Users, (item) => {
        if (item.UserId === payload.MemberUserId) {
          updatedData = [...updatedData, { ...item, IsGroupAdmin: true }]
        }
        else updatedData = [...updatedData, item]
      })
      return {
        ...state,
        makeUserAdminLoading: false,
        groupDetails: { ...state.groupDetails, Users: [...updatedData] }
      }
    case types.MAKE_USER_ADMIN_FAILURE:
      return {
        ...state,
        makeUserAdminLoading: false
      }
    case types.HANDLE_GROUP_KEYS:
      return {
        ...state,
        [action.key]: action.payload
      }
    default:
      return state
  }
};

export default GroupReducer;