import { types } from '../actionTypes/SessionActionTypes';

const SessionReducer = (state = [], action) => {
    const { payload } = action;

    switch (action.type) {
        case types.LOGIN_REQUEST: {
            return { ...state, isLoginLoading: true, isAuthenticating: false };
        }
        case types.LOGIN_SUCCESS: {
            return { ...state, isLoginLoading: false, isAuthenticating: true, sessionData: payload };
        }
        case types.LOGIN_FAILURE: {
            return { ...state, isLoginLoading: false, isAuthenticating: false};
        }
        case types.SIDEBAR_TOGGLE: {
            return { ...state, sidebarShow: payload };
        }
        case types.SIDEBAR_UNFOLDABLE_TOGGLE: {
            return { ...state, sidebarUnfoldable: payload};
        }
        case types.LOGOUT: {
            return { ...state, isAuthenticating: false, sessionData: {} };
          }
        default:
            return state
    }
}
export default SessionReducer;
