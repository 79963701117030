export const types = {
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'GET_USERS_FAILURE',

    GET_USER_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
    GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
    GET_USER_DETAILS_FAILURE: 'GET_USER_DETAILS_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    GET_USER_STATISTICS_REQUEST: 'GET_USER_STATISTICS_REQUEST',
    GET_USER_STATISTICS_SUCCESS: 'GET_USER_STATISTICS_SUCCESS',
    GET_USER_STATISTICS_FAILURE: 'GET_USER_STATISTICS_FAILURE',

    GET_FEEDS_REQUEST: 'GET_FEEDS_REQUEST',
    GET_FEEDS_SUCCESS: 'GET_FEEDS_SUCCESS',
    GET_FEEDS_FAILURE: 'GET_FEEDS_FAILURE',

    GET_USER_FEEDS_REQUEST: 'GET_USER_FEEDS_REQUEST',
    GET_USER_FEEDS_SUCCESS: 'GET_USER_FEEDS_SUCCESS',
    GET_USER_FEEDS_FAILURE: 'GET_USER_FEEDS_FAILURE',

    UPDATE_REPORTED_POST_STATUS_REQUEST: 'UPDATE_REPORTED_POST_STATUS_REQUEST',
    UPDATE_REPORTED_POST_STATUS_SUCCESS: 'UPDATE_REPORTED_POST_STATUS_SUCCESS',
    UPDATE_REPORTED_POST_STATUS_FAILURE: 'UPDATE_REPORTED_POST_STATUS_FAILURE',

    UPDATE_FEED: 'UPDATE_FEED',
    HANDLE_KEYS: 'HANDLE_KEYS',
    GET_TARIFF_DETAILS: 'GET_TARIFF_DETAILS',

    ABILITY_TO_MAKE_PREMIUM_REQUEST:'ABILITY_TO_MAKE_PREMIUM_REQUEST',
    ABILITY_TO_MAKE_PREMIUM_SUCCESS:'ABILITY_TO_MAKE_PREMIUM_SUCCESS',
    ABILITY_TO_MAKE_PREMIUM_FAILURE:'ABILITY_TO_MAKE_PREMIUM_FAILURE',

    ABILITY_TO_CHANGE_EXPIRY_REQUEST:'ABILITY_TO_CHANGE_EXPIRY_REQUEST',
    ABILITY_TO_CHANGE_EXPIRY_SUCCESS:'ABILITY_TO_CHANGE_EXPIRY_SUCCESS',
    ABILITY_TO_CHANGE_EXPIRY_FAILURE:'ABILITY_TO_CHANGE_EXPIRY_FAILURE',

    UPDATE_BULK_REPORTED_POST_STATUS_REQUEST: 'UPDATE_BULK_REPORTED_POST_STATUS_REQUEST',
    UPDATE_BULK_REPORTED_POST_STATUS_SUCCESS: 'UPDATE_BULK_REPORTED_POST_STATUS_SUCCESS',
    UPDATE_BULK_REPORTED_POST_STATUS_FAILURE: 'UPDATE_BULK_REPORTED_POST_STATUS_FAILURE',

    GET_ADMIN_REQUEST: 'GET_ADMIN_REQUEST',
    GET_ADMIN_SUCCESS: 'GET_ADMIN_SUCCESS',
    GET_ADMIN_FAILURE: 'GET_ADMIN_FAILURE',

    DELETE_ADMIN_REQUEST: 'DELETE_ADMIN_REQUEST',
    DELETE_ADMIN_SUCCESS: 'DELETE_ADMIN_SUCCESS',
    DELETE_ADMIN_FAILURE: 'DELETE_ADMIN_FAILURE',

    CHECK_EXISTING_USER_REQUEST: 'CHECK_EXISTING_USER_REQUEST',
    CHECK_EXISTING_USER_SUCCESS: 'CHECK_EXISTING_USER_SUCCESS',
    CHECK_EXISTING_USER_FAILURE: 'CHECK_EXISTING_USER_FAILURE',

    CREATE_USERS_REQUEST:'CREATE_USERS_REQUEST',
    CREATE_USERS_SUCCESS: 'CREATE_USERS_SUCCESS',
    CREATE_USERS_FAILURE: 'CREATE_USERS_FAILURE',
}