import { getStorage, hasStorage } from "../store/localStorage";

const userInitialState = {
  userList: [],
  userListLoading: false,
  userDetails: {},
  userDetailsLoading: false,
  deleteUserLoading: false,
  sidebarShow: true,
  totalUsers: 0,
  userStatisticsData: [],
  userStatisticsLoading: false,
  displaySessionAlert: false,
  getFeedsLoading: false,
  allPosts: [],
  postsToReview: [],
  reportedPosts: [],
  getUserFeeds: [],
  getUserFeedsLoading: false,
  reportedPostLoading: false,
  premiumLoading: false,
  expiryLoading: false,
  tarrifArr: [],
  activePaymentTypes:[],
  paymentTypes:[],
  bulkReportedPostLoading: false,
  allAdmin:[],
  adminListLoading: false,
  totalAdminUsers: 0,
  deleteAdminLoading: false,
  isExistingUserName: false,
  isCheckExistingUserLoading: false,
  createUser: {},
  createUserLoading: false
};

const groupInitialState = {
  allGroupsList: [],
  groupListLoading: false,
  updateGroupListLoading: false,
  deleteGroupLoading: false,
  groupDetails: {},
  totalGroups: 0,
  removeUserLoading: false,
  feedLimit: 10,
  groupFeedsLoading: false,
  editGroupLoading: false,
  hashTagData: [],
  makeUserAdminLoading: false
};

let sessionInitialState = {
  isLoginLoading: false,
  sessionData: {},
  isAuthenticating: false,
  sidebarShow: true,
  sidebarUnfoldable: false,
  theme: 'light',
};

if (hasStorage('userInfo')) {
  sessionInitialState = {
    ...sessionInitialState,
    sessionData: getStorage('userInfo'),
    isAuthenticating: getStorage('isAuthenticating')
  };
}

const initialState = {
  user: userInitialState,
  group: groupInitialState,
  session: sessionInitialState
};

export default initialState;