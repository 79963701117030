import { combineReducers } from 'redux';

import UserReducer from './UserReducer';
import GroupReducer from './GroupReducer';
import SessionReducer from './SessionReducer';

export const reducer = combineReducers({
  user: UserReducer,
  group: GroupReducer,
  session: SessionReducer  
})
